<template>
  <section>
    <div v-if="cancellationMode" class="cancellation-mode">
      <aRow type="flex" justify="space-between">
        <aCol class="upper">
          <img
            class="mr-5"
            src="@/assets/images/dashboard/contracts/cancel-model.png"
            alt="icon"
          />
          Modo Cancelamento

          <aDivider type="vertical" />

          <aCheckbox
            class="cancel-specific"
            v-model="cancelSpecificProducts"
            @change="onChangeCancelSpecificProducts"
          >
            Cancelar produtos específicos
          </aCheckbox>
        </aCol>
        <aCol v-if="!cancelSpecificProducts">
          <aButton
            class="cancel-sale"
            :disabled="cancelSpecificProducts"
            type="link"
            @click="onClickCancelSale"
          >
            <img
              class="mr-5"
              src="@/assets/images/dashboard/contracts/cancel-sale.png"
              alt="icon"
            />
            Cancelar Toda Venda
          </aButton>
        </aCol>
      </aRow>
    </div>

    <div class="contract-header">
      <aRow :gutter="10">
        <aCol :span="4">
          <div class="box">
            <div class="title cprimary">ID {{ tempContract.id }}</div>
            <div class="value dotted-phrase mt-5 f10">
              {{ contract.user ? contract.user.first_name : "" }}
              {{ contract.user ? contract.user.last_name : "" }}
            </div>
          </div>
        </aCol>

        <aCol :span="5">
          <div class="box">
            <div class="title">TOTAL</div>
            <div class="value">
              {{ formatPricePtBr(allContractData.total) }}
            </div>
          </div>
        </aCol>

        <aCol :span="5">
          <div class="box">
            <div class="title">A PAGAR</div>
            <div class="value">
              {{ formatPricePtBr(allContractData.totalNet) }}
            </div>
          </div>
        </aCol>

        <aCol :span="4">
          <div class="box">
            <div class="title">TAXAS</div>
            <div class="value">
              {{ formatPricePtBr(allContractData.totalTaxes) }}
            </div>
          </div>
        </aCol>

        <aCol :span="6">
          <div class="box">
            <div class="title">LUCRO</div>
            <div class="value cgreen">
              {{ formatPricePtBr(allContractData.totalProfit) }}
              ({{ formatPercentage(allContractData.totalProfitPercentage) }}%)
            </div>
          </div>
        </aCol>
      </aRow>
    </div>
  </section>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "ContractModalHeader",
  props: {
    tempContract: Object,
    contract: Object,
    allContractData: Object,
    cancellationMode: Boolean,
    cancelSpecificProducts: Boolean,
    disableCancelSale: Boolean,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
  methods: {
    onChangeCancelSpecificProducts(e) {
      this.$emit("changeCancelSpecificProducts", e.target.checked);
    },
    onClickCancelSale() {
      console.log("onClickCancelSale");
      this.$message.warning(
        "Você precisa cancelar TODOS os produtos para cancelar a venda."
      );
    },
  },
};
</script>

<style lang="scss">
.cancellation-mode {
  .cancel-specific {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #fff;
      border-color: #fff;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid #ff4545;
      border-top: 0;
      border-left: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.cancellation-mode {
  background: #ff4545;
  padding: 6px 15px;
  border-radius: 6px 6px 0 0;
  font-weight: 600;
  color: #fff;
  .cancel-specific {
    color: #fff;
    font-size: 12px;
    text-transform: none;
    font-weight: 500;

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #14952d;
      border-color: #14952d;
    }
  }
  .cancel-sale {
    color: #fff;
    height: auto;
    font-size: 13px;
  }
  .cancel-sale[disabled] {
    opacity: 0.7;
  }
}
</style>

<style lang="sass" scoped>
.contract-header
  padding: 10px
  border-bottom: 1px solid #e0e0e0
  .box
    border-radius: 6px
    font-size: 14px
    padding: 10px
    border-radius: 6px
    border: 1px solid #ddd
    height: 58px
    .title
      color: #bcbec0
      font-weight: 600
    .value
      color: #333
      font-weight: 600
  h1
    font-weight: 600
    font-size: 18px !important
    margin: 0
    padding: 0
    letter-spacing: -1px
    text-transform: uppercase
</style>
